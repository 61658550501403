<template>
    <div>
        <v-dialog
            v-model="dialog"
            persistent
            width="500"
        >
            <v-card class="card-padding">
                <div class="card-form">
                    <div class="header-form">
                        <div class="text-header">{{headerForm}}</div>
                        <div class="pointer" @click="closeModal">X</div>
                    </div>
                    <v-form
                        ref="form"
                        v-model="valid">
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                >
                                    <v-text-field
                                        v-model="form.name"
                                        :rules="inputRules"
                                        label="Name"
                                        :error-messages="errors.name"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                        <div class="btn-container">
                            <v-btn class="mr-4" @click="submit" color="primary">
                                Submit
                            </v-btn>
                            <v-btn @click="closeModal" color="primary" outlined>
                                Cancel
                            </v-btn>
                        </div>
                    </v-form>
                </div>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: 'CourseForm',
    props: {
        mode: {
            type: String,
            default: null
        },
        courseId: {
            type: Number,
            default: null
        }
    },

    data () {
        return {
            dialog: false,
            valid: false,
            inputRules: [
                v => !!v || 'This field is required'
            ],
            form: {
                name: ''
            },
            headerForm: 'Create course',
            errors: []
        }
    },

    methods: {
        notifySuccess (text) {
            this.$notify({
                title: 'Congratulations',
                text: text,
                type: 'success'
            })
        },

        async createCourse () {
            const res = await this.axios.post('courses', this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Create course success')
                this.$emit('create-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async editCourse () {
            const res = await this.axios.put(`courses/${this.courseId}`, this.form)
            if (res && res.data && res.data.status === 'ok') {
                this.notifySuccess('Edit course success')
                this.$emit('edit-success')
                this.closeModal()
            } else if (res && res.data && res.data.status === 'error_inputs') {
                this.errors = res.data.errors
            }
        },

        async submit () {
            if (!this.$refs.form.validate()) {
                return
            }
            if (this.mode === 'create') {
                await this.createCourse()
            } else if (this.mode === 'edit') {
                await this.editCourse()
            }
        },

        closeModal () {
            this.dialog = false
            this.resetForm()
            this.$emit('close-modal')
        },

        resetForm () {
            this.$refs.form.reset()
        },

        async getCourse () {
            const res = await this.axios.get(`courses/${this.courseId}`)
            if (res && res.data && res.data.status === 'ok') {
                this.form = res.data.data
            }
        }
    },

    watch: {
        async mode (newVal, oldVal) {
            if (newVal === 'create') {
                this.headerForm = 'Create course'
            } else if (newVal === 'edit') {
                this.headerForm = 'Edit course'
                this.getCourse()
            }
        }
    }
}
</script>

<style lang="scss" scoped>

</style>
