<template>
    <div>
        <div class="header-page">
            <div class="header-font">
                Courses
            </div>
            <div>
                <v-btn color="primary create-btn" @click="createCourse">Create</v-btn>
            </div>
        </div>
        <v-data-table
            :headers="headers"
            :items="courseList"
            class="elevation-1"
        >
            <template v-slot:[`item.actions`]="{ item }">
                <div class="text-truncate">
                <v-icon
                    small
                    class="mr-2"
                    @click="editCourse(item)"
                >
                    mdi-pencil
                </v-icon>
                <v-icon
                    small
                    @click="deleteModal(item)"
                >
                    mdi-delete
                </v-icon>
            </div>
            </template>
        </v-data-table>
        <course-form
            ref="courseForm"
            :courseId="courseId"
            :mode="mode"
            @create-success="getCoursesList"
            @edit-success="getCoursesList"
            @close-modal="resetValueForm"
        ></course-form>
        <delete-modal
            ref="deleteModal"
            @delete-success="deleteSuccess"
            :deleteForm="deleteForm"
        ></delete-modal>
    </div>
</template>

<script>
import CourseForm from '../components/CourseForm'
import DeleteModal from '../components/DeleteModal'
export default {
    name: 'Course',
    components: {
        CourseForm,
        DeleteModal
    },
    data () {
        return {
            courseList: [],
            headers: [
                { text: 'Name', value: 'name' },
                { text: '', value: 'actions' }
            ],
            courseId: null,
            deleteForm: {
                header: 'Delete course',
                title: 'You want to delete course ',
                url: ''
            },
            mode: ''
        }
    },

    methods: {
        async getCoursesList () {
            const res = await this.axios.get('courses')
            if (res && res.data && res.data.status === 'ok') {
                this.courseList = res.data.data
            }
        },

        createCourse () {
            this.courseId = null
            this.mode = 'create'
            this.openModal()
        },

        editCourse (item) {
            this.courseId = item.id
            this.mode = 'edit'
            this.openModal()
        },

        openModal () {
            this.$refs.courseForm.dialog = true
        },

        deleteModal (item) {
            this.deleteForm.title = `You want to delete course ${item.name} ?`
            this.deleteForm.url = `courses/${item.id}`
            this.$refs.deleteModal.dialog = true
        },

        async deleteSuccess () {
            this.$notify({
                title: 'Congratulations',
                text: 'Delete course success',
                type: 'success'
            })
            await this.getCoursesList()
        },

        resetValueForm () {
            this.mode = ''
            this.courseId = null
        }
    },
    async mounted () {
        await this.getCoursesList()
    }
}
</script>

<style lang="scss" scoped>

</style>
